export default function generateConditionLabel(condition) {
  switch (condition.conditionType) {
    case 'anyOf':
    case 'allOf': {
      const subconditionLabels = condition.subconditions.map(generateConditionLabel);
      const andOr = condition.conditionType === 'anyOf' ? 'OR' : 'AND';
      return `[(${subconditionLabels.join(`) ${andOr} (`)})]`;
    }
    case 'fixedAttributeValueEndorsed': {
      const { uniqueName } = condition.attributeView;
      const uniqueValue = condition.attributeValueView.c2iName;
      return `[${uniqueName}]=${uniqueValue}`;
    }
    case 'symptomEndorsed': {
      const symptomName = condition.symptomView.medicalName;
      return `"${symptomName}" was endorsed`;
    }
    case 'takingMedication': {
      const medicationName = condition.medicationView.genericName;
      return `Pt is taking ${medicationName}`;
    }
    case 'takingAnyMedication': {
      return 'Pt is taking any medication';
    }
    case 'selectedAnyRfv': {
      return 'Pt selected a Reason for Visit';
    }
    case 'ageInRange': {
      const { minAge, maxAge } = condition;
      const minAgeString = typeof minAge === 'object' ? `${minAge.number} ${minAge.unit}` : minAge;
      const maxAgeString = typeof maxAge === 'object' ? `${maxAge.number} ${maxAge.unit}` : maxAge;

      if (minAge && maxAge) return `Age ${minAgeString} - ${maxAgeString}`;
      if (minAge) return `Age ≥ ${minAgeString}`;
      if (maxAge) return `Age ≤ ${maxAgeString}`;
      return 'Error in labelling age range condition';
    }
    case 'scoreInRange': {
      const { lowerBound, upperBound, scoreView } = condition;
      const scoreName = scoreView.uniqueName;
      if (lowerBound && upperBound) {
        return lowerBound === upperBound
          ? `${scoreName} = ${lowerBound}`
          : `${scoreName} ${lowerBound} - ${upperBound}`;
      }
      if (lowerBound) return `${scoreView.uniqueName} ≥ ${lowerBound}`;
      if (upperBound) return `${scoreView.uniqueName} ≤ ${upperBound}`;
      return 'Error in labelling score-in-range condition';
    }
    case 'sexIsMale': {
      return 'Sex is Male';
    }
    case 'sexIsFemale': {
      return 'Sex is Female';
    }
    case 'sexIsUndefined': {
      return 'Sex is undefined';
    }
    default:
      return 'Error in retrieving conditional';
  }
}
